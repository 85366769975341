<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="setupOffcanvas" aria-labelledby="setupOffcanvasLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"><i class="fa-duotone fa-gears"></i> Setups</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="form-check form-switch mb-3">
        <input class="form-check-input" type="checkbox" role="switch" id="nextDaySwitch" :checked="nextDaySwitch" @click="nextDaySwitchHandle">
        <label class="form-check-label" for="nextDaySwitch">
          there should be no confirmation before the next day action
        </label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="nextActionFinishSwitch" :checked="nextActionFinishSwitch" @click="nextActionFinishSwitchHandle">
        <label class="form-check-label" for="nextActionFinishSwitch">
          there should be no confirmation before the next action finish day
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";

export default {
  name: "Setup",
  props: ["initData", "contents", "simulationData"],
  data() {
    return {

    };
  },
  computed: {
    nextDaySwitch(){
      return this.simulationData.setup.nextDaySwitch
    },
    nextActionFinishSwitch(){
      return this.simulationData.setup.nextActionFinishSwitch
    }
  },
  methods: {
    nextDaySwitchHandle(){
      let selectedCourseId = this.initData.user.selectedCourseId;
      this.simulationData.setup.nextDaySwitch = !this.nextDaySwitch;
      localStorage.setItem('vMine_'+selectedCourseId+'_nextDaySwitch', this.nextDaySwitch);
    },
    nextActionFinishSwitchHandle(){
      let selectedCourseId = this.initData.user.selectedCourseId;
      this.simulationData.setup.nextActionFinishSwitch = !this.nextActionFinishSwitch;
      localStorage.setItem('vMine_'+selectedCourseId+'_nextActionFinishSwitch', this.nextActionFinishSwitch);
    },
    openOffset() {
      setTimeout(() => {
        let modalInstance = new Offcanvas(document.getElementById("setupOffcanvas"));
        modalInstance.show();
      }, 0);
    }
  },
};
</script>

<style scoped>
.offcanvas-body{
  background-color: var(--wrapper-bg);
}
</style>
