<template>
    <div class="alert-deck">
        <Alerts :alerts="alert" v-for="alert in initData.alerts" />
    </div>
    <InitLoader :initData="initData"></InitLoader>
    <Loader :initData="initData"></Loader>
    <Header
        v-if="initData.initLoader===false && initData.contents!==null && !notDisplayHeader.includes(currentRouteName)"
        :initData="initData" :contents="activeContent"></Header>
    <router-view v-if="initData.initLoader===false && initData.contents!==null" :initData="initData"
                 :contents="activeContent" />
    <Footer v-if="initData.initLoader===false && initData.contents!==null && !notDisplayFooter.includes(currentRouteName)" :initData="initData"
            :contents="activeContent"></Footer>
  <Download v-if="initData.initLoader===false" :initData="initData"></Download>
</template>

<script>
import {reactive} from "vue";
import Alerts from "@/components/Alerts.vue";
import {Response} from "@/classes/response";
import InitLoader from "@/components/InitLoader.vue";
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Download from "@/components/Download.vue";

export default {
    name: "App",
    components: {
        Footer,
        Header,
        Loader,
        InitLoader,
        Alerts,
        Download
    },
    data() {
        const initData = reactive({
            "user": null,
            "initLoader": true,
            "loader": {
                "visible": false,
                "upload" : null,
                "msg" : null
            },
            "alerts": [],
            "modals": {
              "download": {
                "imageSrc": null,
                "pdfSrc": null
              },
              "inviteDeleteAgree": null,
              "userDeleteAgree": null,
              "courseDeleteAgree": null,
              "courseUserDeleteAgree": null,
            },
            "language": null,
            "contents": null,
            "simulation": null,
        });

        return {
            initData,
            notDisplayHeader: ["undefined", "login", "registration", "registrationSuccess", "forgottenPassword", "newPasswordConfirmation", "registrationConfirmation"],
            notDisplayFooter: ["undefined", "login", "registration", "registrationSuccess", "forgottenPassword", "newPasswordConfirmation", "registrationConfirmation"],
            notAuthContent: ["undefined", "mainPage", "login", "registration", "registrationSuccess", "forgottenPassword", "newPasswordConfirmation", "registrationConfirmation"],
        };
    },
    computed: {
        activeContent() {
            let userLanguage = this.initData.language
            if (userLanguage === null) {
                return []
            } else {
                return this.initData.contents[userLanguage]
            }
        },
        currentRouteName() {
            let routeName = this.$route.name
            //IF NOT LOGINED BUT WANT SEE AUT REQUIRED PAGE
            if (this.initData.user !== null && !this.notAuthContent.includes(routeName) && this.initData.user.auth === false) {
                this.$router.push({name: "login"})
            }
            if (routeName === undefined) {
                return "undefined"
            } else {
                return routeName
            }
        },
    },
    created() {
        this.userInit();
    },
    methods: {
        userInit() {
            this.$axios.get("/api/v1/user")
                .then((response) => {
                    let responseData = new Response().handle(response);
                    this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

                    if (responseData.status === true) {
                        this.initData.language = responseData.data.language
                        this.initData.contents = responseData.data.contents
                        this.initData.user = responseData.data.user
                        this.initData.serverDateTime = responseData.data.serverDateTime
                    }
                })
                .catch(error => {
                    console.error(error);
                    let response = new Response().errorHandle(error.response);
                    this.initData.alerts.push({msg: response.msg, type: response.msgType});
                })
                .then(() => {
                    this.initData.initLoader = false;
                });
        },
    },
};

</script>