<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-8 d-flex flex-wrap justify-content-center justify-content-md-start align-items-center">
          <!-- Image 1 -->
          <div class="image-container">
            <p class="small text-muted mb-1 mt-3"></p>
            <img
                class="footer-image me-3 mb-3"
                :src="leftImages[0]"
                alt="Footer Image"
            />
          </div>
          <!-- Image 2 -->
          <div class="image-container">
            <p class="small text-muted mb-1 mt-3"></p>
            <img
                class="footer-image me-3 mb-3"
                :src="leftImages[1]"
                alt="Footer Image"
            />
          </div>
          <!-- Image 3 -->
          <div class="image-container">
            <p class="small text-muted mb-1 mt-3">Funded by: </p>
            <img
                class="footer-image me-3 mb-3"
                :src="leftImages[2]"
                alt="Footer Image"
            />
          </div>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center align-items-md-end justify-content-center justify-content-md-end">
          <p class="small text-muted footer-developed-by mb-1 mt-3">{{ contents.footer.developed }}</p>
          <a href="https://www.ecosim.hu/" target="_blank" rel="noopener noreferrer">
            <img
                class="footer-developed-by"
                :src="developedByImage"
                alt="Developed by"
            />
          </a>
        </div>
        <div class="col-12 mt-5 small text-muted">
          © {{ new Date().getFullYear() }} {{ contents.footer.copyright }}
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: "Footer",
  props: ["contents"],
  data() {
    return {
      leftImages: [
        require("@/assets/images/Lulea_tekniska_universitet_Logo.svg"),
        require("@/assets/images/logo_emerald-1.png"),
        require("@/assets/images/eit_main_logo.jpg"),
      ],
      developedByImage: require("@/assets/images/ecosim_eng_cmyk_png.png"),
    };
  },
};
</script>

<style scoped>
footer {
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
}

.footer-image {
  width: auto;
  height: 60px;
  margin: 5px;
}

.footer-developed-by {
  width: 140px;
}
</style>
