<template>
  <div v-if="simulationData.modals.test" class="modal" id="testModal" tabindex="-1" aria-labelledby="testModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="testModalLabel">
            <i class="fa-duotone fa-flask-vial"></i> Test
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            You will test the following {{blocks.length}} blocks:<br>
            <span v-for="block in blocks">
              #{{ block.id}}
            </span>
          </p>
          <p>
            Process time: {{simulationSetup.processTime.test}} day / block<br>
            When finished with the last block: {{simulationSetup.processTime.test}} days<br>
            Cost per block: {{ nF.formatNumber(getCostPerBlock) }}<br>
            Sum cost: {{ nF.formatNumber(getSumCostWithoutTested) }}
          </p>
          <div class="mb-3">
            <label>Test</label>
            <select class="form-control mb-3" id="testSelect" multiple v-model="testType">
              <option v-for="test in simulationData.setup.blockTests" :value="test.id">{{ test.name }}</option>
            </select>
            <div class="small text-danger" v-if="testTypeError">
              {{testTypeError}}
            </div>
          </div>
          <div class="mb-3" v-if="flowSheetRequired">
            <label>Flow sheet</label>
            <select class="form-control" id="flowSheetSelect" multiple v-model="flowSheet">
              <option v-for="flowSheet in simulationData.setup.flowSheets" :value="flowSheet.id">{{ flowSheet.name }}</option>
            </select>
            <div class="small text-danger" v-if="flowSheetError">
              {{flowSheetError}}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary float-end" @click="test">
            <i class="fa-duotone fa-flask-vial"></i> Test
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TomSelect from "tom-select";
import { Modal } from "bootstrap";
import {NumberFunctions} from "@/classes/number";
import Simulation from "@/views/Simulation/Simulation.vue";
import {Validate} from "@/classes/validate";

export default {
  name: "Test",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    let simulationSetup = this.simulationData.setup

    return {
      nF,
      simulationSetup,
      blockTests: simulationSetup.blockTests,
      tsTestSelect :null,
      tsFlowSheetSelect :null,
      testType: [],
      testTypeError: null,
      flowSheet : [],
      flowSheetError: null,
      Simulation
    };
  },
  updated() {
    let tsTestConfig = {
      maxOptions: null,
      maxItems: this.blockTests.length,
      plugins: {
        remove_button: {
          title: "Delete",
        },
      },
      hideSelected: true,
      render: {
        no_results: function (data, escape) {
          return '<div class="no-results">Nincs találat</div>';
        },
      },
    };

    if(this.tsTestSelect===null){
      this.tsTestSelect = new TomSelect("#testSelect", tsTestConfig);
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.test;
    },
    getCostPerBlock() {
      let cost = 0
      //GET COST FROM blockTests BY testType
      for(let i=0; i<this.testType.length; i++){
        let testType = this.testType[i]
        //GET TEST TYPE
        let test = this.simulationData.setup.blockTests.find(x => x.id === testType)
        let flowSheetRequired = test.flowSheetRequired
        if(flowSheetRequired===false){
          cost += test.cost
        }else{
          //GET FLOWSHEET SELECTED
          cost += test.cost * this.flowSheet.length
        }
      }
      //INFLATION
      cost = cost * this.simulationSetup.actualInflation
      //RETURN
      return cost
    },
    getSumCostWithoutTested(){
      //GET COSTS BY TEST TYPE
      let testTypeCosts = []
      let testTypeFlowSheetRequireds = []
      for(let i=0; i<this.testType.length; i++){
        let testType = this.testType[i]
        let blockTest = this.blockTests.find(x => x.id === testType)
        testTypeCosts[testType] = blockTest.cost
        testTypeFlowSheetRequireds[testType] = blockTest.flowSheetRequired
      }
      let blocks = this.blocks
      let sum = 0
      for(let i=0; i<blocks.length; i++){
        let block = blocks[i]
        let testData = block.tD
        if(testData===null){
          //LOOP testTypeCosts BY KEYS
          for(let key in testTypeCosts){
            let testTypeCost = testTypeCosts[key]
            let testTypeFlowSheetRequired = testTypeFlowSheetRequireds[key]
            if (testTypeFlowSheetRequired===false){
              sum += testTypeCost
            }else{
              sum += testTypeCost * this.flowSheet.length
            }
          }
        }else{
          //LOOP BY testType
          for(let j=0; j<this.testType.length; j++){
            let testType = this.testType[j]
            let testTypeCost = testTypeCosts[testType]
            let testTypeFlowSheetRequired = testTypeFlowSheetRequireds[testType]
            //TESTTYPE IN TESTDATA
            if(testData[testType]!==undefined){
              if (testTypeFlowSheetRequired===true){
                //LOOP FLOWSHEET
                for (let k=0; k<this.flowSheet.length; k++){
                  let flowSheetId = this.flowSheet[k]
                  //CHECK FLOWSHEET IN TESTDATA
                  if(testData[testType][flowSheetId]===undefined) {
                    sum += testTypeCost
                  }
                }
              }
            }else{
              if (testTypeFlowSheetRequired===false){
                sum += testTypeCost
              }else{
                sum += testTypeCost * this.flowSheet.length
              }
            }
          }
        }
      }
      //INFLATION
      sum = sum * this.simulationSetup.actualInflation
      //RETURN
      return sum
    },
    flowSheetRequired(){
      let flowSheetRequired = false
      //LOOP TEST TYPES KEYS
      for(let i=0; i<this.testType.length; i++){
        let testType = this.testType[i]
        //GET TEST TYPE
        let test = this.simulationData.setup.blockTests.find(x => x.id === testType)
        flowSheetRequired = test.flowSheetRequired
        //IF FLOWSHEET REQUIRED
        if(flowSheetRequired){
          let tsFlowSheetConfig = {
            maxOptions: null,
            maxItems: this.simulationData.setup.flowSheets.length,
            plugins: {
              remove_button: {
                title: "Delete",
              },
            },
            hideSelected: true,
            render: {
              no_results: function (data, escape) {
                return '<div class="no-results">Nincs találat</div>';
              },
            },
          };

          setTimeout(() => {
            if(this.tsFlowSheetSelect===null){
              this.tsFlowSheetSelect = new TomSelect("#flowSheetSelect", tsFlowSheetConfig);
            }
            //ADD FLOWSHEET SELECTED
          }, 50);
          return true
        }
      }
      this.tsFlowSheetSelect = null
      return false
    },
    content() {
      return this.contents.testModal
    },
  },
  methods: {
    openModal(e, blocks) {
      e.simulationData.modals.test = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("testModal"));
        modalInstance.show();
      }, 50);
    },
    test(){
      //VALIDATE
      let validate = new Validate()
      //TEST TYPE
      this.testTypeError = validate.emptyField(this.testType, this.content.errors.testTypeRequired)
      //FLOWSHEET
      if(this.flowSheetRequired){
        this.flowSheetError = validate.emptyField(this.flowSheet, this.content.errors.flowSheetRequired)
      }

      if(this.testTypeError || this.flowSheetError){
        return false
      }
      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("testModal"));
      modalInstance.hide();
      //ACTION
      this.Simulation.methods.action(this, 12, this.blocks, {
        testType: this.testType,
        flowSheet: this.flowSheet
      })
    }
  },
};
</script>

<style scoped>
</style>
