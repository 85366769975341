<template>
  <div v-if="simulationData.modals.closeProject.projectId!==null" class="modal" id="closeProjectModal" tabindex="-1" aria-labelledby="closeProjectModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="closeProjectModalLabel">
            <i class="fa-duotone fa-lock"></i> Close project
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>You are about to close the next project<br>
            <strong>Project #{{projectId}}</strong>
          </p>
          <p>After closing the project, you will not be able to mine new blocks related to this project.</p>
          <p>Are you sure to close project?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="closeProject" data-bs-dismiss="modal">
            <i class="fa-duotone fa-lock"></i> Close project
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {NumberFunctions} from "@/classes/number";
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "CloseProject",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    let simulationSetup = this.simulationData.setup

    return {
      nF,
      Simulation,
      projectTypes : simulationSetup.projects.types,
      projectType: '',
      vehicle: null,
    }
  },
  computed: {
    projectId() {
      return this.simulationData.modals.closeProject.projectId
    },
  },
  methods: {
    openModal(e, projectId) {
      e.simulationData.modals.closeProject.projectId = projectId

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("closeProjectModal"));
        modalInstance.show();
      }, 100);
    },
    closeProject(){
      const data = {
        projectId: this.projectId
      }
      this.Simulation.methods.action(this, 25, null,data)
    }
  },
};
</script>

<style scoped>

</style>