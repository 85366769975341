<template>
  <div v-if="simulationData.modals.mine.blocks.length>0" class="modal" id="mineModal" tabindex="-1" aria-labelledby="mineModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="mineModalLabel">
            <i class="fa-duotone fa-pickaxe"></i> Mine
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <p>
              You will open a mine on the following blocks:<br>
              <span v-for="block in modalBlocks">
              #{{ block.id}}
              </span>
            </p>
          </div>
          <div class="mb-3">
            <label for="operationSelect" class="form-label">Select operation:</label>
            <select v-model="selectedOperation" class="form-select" id="operationSelect">
              <option value="0">Select an option...</option>
              <option value="1">Mine and send to waste</option>
              <option value="2">Mine and stock</option>
              <option value="3">Mine, process, and sell</option>
              <option value="4">Mine, process, and stock</option>
            </select>
            <div class="small text-danger" v-if="selectedOperationError">
              {{selectedOperationError}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary float-end" :disabled="this.simulationData.isShaftRequired" @click="mine">
              <i class="fa-duotone fa-pickaxe"></i> Mine
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {NumberFunctions} from "@/classes/number";
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";
import {Validate} from "@/classes/validate";

export default {
  name: "Mine",
  components: { Simulation },
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    const { blocks } = this.simulationData
    const { setup } = this.simulationData
    const { map } = setup
    const { x } = map
    const { y } = map

    return {
      nF,
      Simulation,
      blocks,
      x,
      y,
      selectedOperation: 0,
      selectedOperationError: null,
      allNeighborsPIds: []
    }
  },
  computed: {
    modalBlocks() {
      return this.simulationData.modals.mine.blocks
    },
    content() {
      return this.contents.mineModal
    },
    isShaftRequired(){
      return this.simulationData.isShaftRequired
    },
    projects(){
      return this.simulationData.projects
    }
  },
  methods: {
    mine(){
      const allNeighbors = [];
      this.modalBlocks.forEach((block) => {
        const neighbors = this.getNeighborsPID(block, this.blocks);
        neighbors.forEach((neighbor) => {
          allNeighbors.push(neighbor);
        });
      });
      this.allNeighborsPIds = allNeighbors.map((neighbor) => neighbor.pId);

      //CHECK IF SHAFT IS REQUIRED
      // const count = this.blocks.filter((block) => block.pId === this.mostCommonNumber(this.allNeighborsPIds) && ![12, 13, 15, 27].includes(block.s) && block.pT === 2).length;
      // const blockCount = 100
      // if (count > blockCount && ((Math.floor(count / blockCount) * blockCount) % blockCount === 0 || count % blockCount === 0)) {
      //   // Check if the pId of the blocks belongs to a project with a project type of 1
      //   const hasProjectType1 = this.projects.find(project => project.id === this.mostCommonNumber(this.allNeighborsPIds) && project.type === 1);
      //   if (!hasProjectType1) {
      //     this.simulationData.isShaftRequired = true
      //   }
      // }

      //VALIDATE//VALIDATE
      let validate = new Validate()
      this.selectedOperationError = validate.selected(this.selectedOperation, this.content.errors.selectedOperationRequired)

      if(this.selectedOperationError){
        return false
      }

      let data = {
        'isShaftRequired': this.isShaftRequired,
        operation: this.selectedOperation
      }
      let action = 18 + parseInt(this.selectedOperation)

      this.Simulation.methods.action(this, action, this.modalBlocks,data)
      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("mineModal"));
      modalInstance.hide();
      //RESET
      this.selectedOperation = 0
    },
    getNeighborsPID(block, map) {
      const neighbors = [];
      const { x, y, l } = block;

      // Check for neighbors to the left, right, top, bottom and above and below
      if (x > 0) neighbors.push(this.getBlockAt(x - 1, y, map, l))
      if (x < 40) neighbors.push(this.getBlockAt(x + 1, y, map, l))
      if (y > 0) neighbors.push(this.getBlockAt(x, y - 1, map, l))
      if (y < 40) neighbors.push(this.getBlockAt(x, y + 1, map, l))
      if (l > 0) neighbors.push(this.getBlockAt(x, y, map, l - 1))
      if (l < 11) neighbors.push(this.getBlockAt(x, y, map, l + 1))

      neighbors.forEach((neighbor) => {
        if (neighbor !== undefined) {
          if (neighbor.hasOwnProperty('pId')) {
            block.pId = neighbor.pId
          }
        }
      })

      return neighbors.filter((neighbor) => neighbor !== undefined);
    },

    getBlockAt(x, y, map, l) {
      // Assuming you have access to the blocks array
      return map.find((block) => block.x === x && block.y === y && block.l === l);
    },
    mostCommonNumber() {
      const arr = this.allNeighborsPIds
      // Filter out undefined values
      const filteredArray = arr.filter(value => typeof value === 'number');

      // Check if the array is empty after filtering
      if (filteredArray.length === 0) {
        return 0
      }

      // Count occurrences of each number
      const occurrences = {};
      filteredArray.forEach(number => {
        occurrences[number] = (occurrences[number] || 0) + 1;
      });

      // Find the most common number
      let mostCommon = null;
      let maxOccurrences = 0;

      for (const number in occurrences) {
        if (occurrences[number] > maxOccurrences) {
          mostCommon = number;
          maxOccurrences = occurrences[number];
        }
      }

      return +mostCommon;
    },
    openModal(e, blocks, map) {
      e.simulationData.modals.mine.blocks = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("mineModal"));
        modalInstance.show();
      }, 0);
    }
  },
};
</script>

<style scoped>

</style>