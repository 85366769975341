<template>
  <div v-if="simulationData.modals.newProject.blocks.length>0" class="modal" id="newProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="newProjectModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newProjectModalLabel">
            <i class="fa-duotone fa-dungeon"></i> Open new project
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <p v-if="blocks[0].s === 10">
            <strong>You can not open a new project on a road block.</strong>
          </p>
          <div v-else>
            <p>
              You will open a new project on the following block:<br>
              #{{ blocks[0].id}}
            </p>
            <p v-if="projectType===2">
              If underground project, this blocks will be shaft:<br>
              <span v-for="block in this.simulationData.blocksForActions.slice(1)">
              #{{ block.id}}
              </span>
            </p>
            <p class="mb-0">
              The shortest road to the plant entry is: <strong>{{ plantPathLength }}</strong> blocks
            </p>
            <p class="mb-0">
              The shortest road to the tailing entry is: <strong>{{ tailingPathLength }}</strong> blocks
            </p>
            <p>
              The shortest road to the ROM stockpile entry is: <strong>{{ rawStockPathLength }}</strong> blocks
            </p>
          </div>
          <div v-if="projectHasConnection && !(blocks[0].s === 10)">
            <div class="mb-3">
              <label for="projectType">Select project type</label>
              <select id="projectType" class="form-select mb-2" v-model="projectType" :disabled="switcher">
                <option value="">Select project type</option>
                <option v-for="type in projectTypes" :value="type.id">{{ type.name }}</option>
              </select>
            </div>
            <div class="mb-3" v-if="vehicles.length > 1">
              <label for="vehicle">Select Vehicle:</label>
              <select id="vehicle" class="form-select mb-2" v-model="vehicle">
                <option v-for="vehicle in vehicles" :value="vehicle.id">{{ vehicle.name }}</option>
              </select>
            </div>
          </div>
          <p v-if="projectHasConnection && !(blocks[0].s === 10)">
            Project investment: {{ nF.formatNumber(projectCost) }}<br>
            Vehicle investment: {{ nF.formatNumber(vehicleCost) }}<br>
            Total investment: {{ nF.formatNumber(totalCost) }}
          </p>
          <p v-if="projectHasConnection && !(blocks[0].s === 10)">Are you sure to open new project?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" @click="closeModal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="newProject" data-bs-dismiss="modal" :class="{'disabled': !projectHasConnection || blocks[0].s === 10 || projectType === '' || vehicle === null}">
            <i class="fa-duotone fa-dungeon"></i> Open new project
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {NumberFunctions} from "@/classes/number";
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "NewProject",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    let simulationSetup = this.simulationData.setup

    return {
      nF,
      Simulation,
      projectTypes : simulationSetup.projects.types,
      projectType: '',
      vehicle: null,
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.newProject.blocks
    },
    plantPathLength() {
      return this.simulationData.modals.newProject.plantPathLength
    },
    tailingPathLength() {
      return this.simulationData.modals.newProject.tailingPathLength
    },
    rawStockPathLength() {
      return this.simulationData.modals.newProject.rawStockPathLength
    },
    switcher() {
      let switcher = this.simulationData.modals.newProject.switcher
      if(switcher){
        this.projectType = 2
      }
      return switcher
    },
    projectHasConnection() {
      return this.plantPathLength !== false && this.tailingPathLength !== false && this.rawStockPathLength !== false
    },
    vehicles() {
      let projectType = this.projectType
      if(projectType=== ''){
        return []
      }
      let vehicles = this.projectTypes.find(p => p.id === projectType).vehicles
      this.vehicle = vehicles[0].id
      return vehicles
    },
    vehicleCost() {
      let vehicle = this.vehicles.find(v => v.id === this.vehicle)
      if(vehicle===undefined){
        return 0
      }
      return vehicle.price * vehicle.necessary
    },
    projectCost() {
      let projectData = this.projectTypes.find(p => p.id === this.projectType)
      if(projectData===undefined){
        return 0
      }
      return projectData.mandatory
    },
    totalCost() {
      return this.vehicleCost + this.projectCost
    },
    isShaftRequired(){
      let projectData = this.projectTypes.find(p => p.id === this.projectType)
      if(projectData===undefined){
        return false
      }
      return projectData.isShaftRequired
    }
  },
  watch: {
    projectType() {
      let projectType = this.projectType
      if(projectType === 2){
        let blockData = this.blocks[0]
        let blocks = this.simulationData.blocks.filter((block) => block.x === blockData.x && block.y === blockData.y && block.l > blockData.l);
        blocks.forEach((block) => {
          this.simulationData.blocksForActions.push(block);
        });
      }else{
        //REMOVE SHAFTS
        let blockData = this.blocks[0]
        let blocks = this.simulationData.blocks.filter((block) => block.x === blockData.x && block.y === blockData.y && block.l !== blockData.l);
        blocks.forEach((block) => {
          this.simulationData.blocksForActions = this.simulationData.blocksForActions.filter((b) => b.id !== block.id);
        });
      }
    }
  },
  methods: {
    closeModal() {
      this.projectType = '';
      this.simulationData.modals.newProject.switcher = false

      const modalElement = document.getElementById("newProjectModal");
      const modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
    openModal(e, blocks, map, switcher) {
      const modals = e.simulationData.modals.newProject;

      modals.blocks = blocks;

      e.simulationData.modals.newProject.switcher = switcher

      let projectEntrance;
      if (switcher) {
        const block = blocks[0];
        const blockPID = block.pId;
        const project = e.simulationData.projects.find((project) => project.id === blockPID);

        projectEntrance = project.entrance;

        modals.plantPathLength = project.paths.plant;
        modals.tailingPathLength = project.paths.tailing;
        modals.rawStockPathLength = project.paths.rawStock;
      } else {
        const entrances = {
          plant: 7,
          tailings: 8,
          rawStock: 22,
        };
        projectEntrance = blocks[0];

        const findEntrance = (type) => map.find((block) => block.s === entrances[type]);

        modals.plantPathLength = Simulation.methods.findPath(e, projectEntrance, findEntrance('plant'), map, 10);
        modals.tailingPathLength = Simulation.methods.findPath(e, projectEntrance, findEntrance('tailings'), map, 10);
        modals.rawStockPathLength = Simulation.methods.findPath(e, projectEntrance, findEntrance('rawStock'), map, 10);
      }

      setTimeout(() => {
        const modalInstance = new Modal(document.getElementById("newProjectModal"));
        modalInstance.show();
      }, 100);
    },
    newProject(){
      const data = {
        'projectType': this.projectType,
        'isShaftRequired': this.isShaftRequired,
        'vehicle': this.vehicle,
        'pathLengths' : {
          'plant': this.plantPathLength,
          'tailing': this.tailingPathLength,
          'rawStock': this.rawStockPathLength,
        }
      }
      this.projectType = ''
      this.Simulation.methods.action(this, 15, this.blocks,data)
    }
  },
};
</script>

<style scoped>

</style>