<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <router-link class="navbar-brand" :to="{ name: 'mainPage' }">
          <img src="@/assets/images/vmine_logo.svg" alt="VMine logo">
        </router-link>
        <div v-if="!auth" class="fs-4 ms-2">VMine Geometallurgy Simulation</div>
      </div>
      <div v-if="auth" class="user-block order-2 order-lg-3 ms-auto">
        <div class="full-name">{{firstName}} {{lastName}}</div>
        <div class="permission small">{{permissionName}}</div>
        <a class="selected-course small text-muted" v-if="courses.filter((course) => course.deleteDateTime===null).length<2">
          {{selectedCourseName}}
        </a>
        <router-link :to="{name:'changeCourse'}" class="selected-course small text-muted" v-if="courses.filter((course) => course.deleteDateTime===null).length>1">
          {{selectedCourseName}} <i class="fa-duotone fa-check-double"></i>
        </router-link>
        <router-link :to="{name:'courseDashBoard',params: {courseId : selectedCourseId}}" class="small text-danger ps-2 ms-1" v-if="permissionLevel===90 && selectedCourseId!==null">
          <i class="fa-duotone fa-gears"></i>
        </router-link>
      </div>
      <button class="navbar-toggler order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="auth" class="collapse navbar-collapse order-4 order-lg-2" id="navbarNav">
          <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                  <router-link class="nav-link" :to="{name: 'courses'}"><i class="fa-duotone fa-list"></i> Courses</router-link>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link" :class="{'disabled':selectedCourseId===null}" :to="{name: 'myCompany'}"><i class="fa-duotone fa-building"></i> My company</router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" :class="{'disabled':selectedCourseId===null}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa-duotone fa-pickaxe"></i> Simulation
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationOverview'}">
                      <i class="fa-duotone fa-bars-staggered"></i> Overview
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationMap'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-layer-group"></i> Map
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationBlockDatabase'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-server"></i> Database
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationProjects'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-dungeon"></i> Projects
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationStock'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-cubes-stacked"></i> Stock
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationTailings'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-water-arrow-down"></i> Mine waste
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationUpgrades'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-book-arrow-up"></i> Upgrades
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationProfitAndLoss'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-wallet"></i> Finance
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationStatistics'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-chart-line-up"></i> Statistics
                    </router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'simulationDocuments'}" :class="{'disabled':permissionLevel!==90 && simulationStartDateTime!==null && serverDateTime<simulationStartDateTime}">
                      <i class="fa-duotone fa-files"></i> Documents
                    </router-link>
                  </li>
                  <li>
                    <a class="dropdown-item" @click="Download.methods.document(this,13,6,teamId)">
                      <i class="fa-duotone fa-book"></i> Game rules
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown" v-if="permissionLevel===90">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa-duotone fa-folder-gear"></i> Admin
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" :to="{name:'masterExcelUpload'}"><i class="fa-duotone fa-file-excel"></i> Master excels upload</router-link>
                  </li>
                  <li>
                    <router-link class="dropdown-item" :to="{name:'gameDocumentsUpload'}"><i class="fa-duotone fa-files"></i> Game documents upload</router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                  <a class="nav-link" @click="logout()"><i class="fa-duotone fa-power-off"></i> Log out</a>
              </li>
          </ul>
      </div>
      <div class="order-5" v-if="!auth">
        <router-link :to="{name:'registration'}" class="btn btn-sm btn-secondary me-2"><i class="fa-duotone fa-id-card"></i> Registration</router-link>
        <router-link :to="{name:'login'}" class="btn btn-sm btn-primary"><i class="fa-duotone fa-right-to-bracket"></i> Login</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import Download from "@/components/Download";
import {Response} from "@/classes/response";

export default {
    name: "Header",
    props: ["initData", "contents"],
    data(){
        return{
          Download
        }
    },
    computed:{
        teamId(){
          if (this.initData.user.myCompany !== null) {
            return this.initData.user.myCompany.teamId
          }
        },
        auth(){
            return this.initData.user.auth
        },
        firstName(){
            return this.initData.user.firstName
        },
        lastName(){
            return this.initData.user.lastName
        },
        permissionName(){
            return this.initData.user.permissionName
        },
        permissionLevel(){
            return this.initData.user.permissionLevel
        },
        selectedCourseName(){
            let selectedCourseId = this.initData.user.selectedCourseId
            let courses = this.initData.user.courses
            if(selectedCourseId === null || courses === null || courses.length === 0){
              return 'No course yet'
            }

            let selectedCourse = courses.find(course => course.id === selectedCourseId)
            return selectedCourse.name
        },
        selectedCourseId(){
            return this.initData.user.selectedCourseId
        },
        simulationStartDateTime(){
            let selectedCourseId = this.initData.user.selectedCourseId
            let courses = this.initData.user.courses
            if(selectedCourseId === null || courses === null || courses.length === 0){
              return null
            }

            let selectedCourse = courses.find(course => course.id === selectedCourseId)
            return selectedCourse.simulationStartDateTime
        },
      	courses(){
            return this.initData.user.courses
        },
        serverDateTime(){
          return this.initData.serverDateTime
        }
    },
    watch:{
      teamId(){}
    },
    methods: {
      logout(){
        this.initData.loader.visible = true;
        this.$axios.get("/api/v1/user/logout")
            .then((response) => {
              let responseData = new Response().handle(response);
              this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

              if (responseData.status === true) {
                this.initData.user.auth = false
                this.initData.simulation = null
                //SESSION HASH STORAGE
                localStorage.removeItem("vMine_sessionHash");
                this.$axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("vMine_sessionHash")}`;
                //REDIRECT
                this.$router.push({name: 'mainPage'});
              }
            })
            .catch(error => {
              console.error(error);
              let response = new Response().errorHandle(error.response);
              this.initData.alerts.push({msg: response.msg, type: response.msgType});
            })
            .then(() => {
              this.initData.loader.visible = false;
            });
      }
    }
};
</script>

<style scoped>
.navbar-brand img {
  height: 50px;
}
.navbar-collapse{
  padding-right: 1rem;
}
.user-block{
  text-align: right;
}
.user-block .full-name{
  line-height: 1rem;
}
.user-block .permission{
  color: var(--vmine-yellow);
  line-height: 1rem;
}
.active-link{
  color: var(--vmine-yellow);
}
a{
  cursor: pointer;
}
.dropdown-menu {
  --bs-dropdown-zindex: 1073;
}
@media (max-width: 992px) {
  .user-block{
    margin-right: 0!important;
    padding-right: 1rem;
  }
  .navbar-collapse{
    text-align: right;
  }
  .dropdown-menu{
    text-align: right;
  }
}
</style>