<template>
  <div v-if="simulationData.modals.processing.blocks.length>0" class="modal" id="processingModal" tabindex="-1" aria-labelledby="processingModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="processingModalLabel">
            <i class="fa-duotone fa-microchip"></i> Processing
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <p>
              You will process the following blocks:<br>
              <span v-for="block in modalBlocks">
              #{{ block.id}}
              </span>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary float-end" @click="processing">
              <i class="fa-duotone fa-microchip"></i> Processing
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "Processing",
  components: { Simulation },
  props: ["initData", "contents", "simulationData"],
  data() {
    return {
      Simulation
    }
  },
  computed: {
    modalBlocks() {
      return this.simulationData.modals.processing.blocks
    },
    content() {
      return this.contents.processingModal
    },
  },
  methods: {
    processing(){
      this.Simulation.methods.action(this, 23, this.modalBlocks,null)

      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("processingModal"));
      modalInstance.hide();
    },
    openModal(e, blocks, map) {
      e.simulationData.modals.processing.blocks = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("processingModal"));
        modalInstance.show();
      }, 0);
    }
  },
};
</script>

<style scoped>

</style>