<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="timelineOffcanvas" aria-labelledby="timelineOffcanvasLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"><i class="fa-duotone fa-timeline"></i> Timeline</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div
          v-for="(timeline, index) in histories"
          :key="index"
          class="card mb-1"
          :class="{'border-success': actualDay>=timeline.endDay,'border-warning': actualDay<timeline.endDay}"
      >
        <div class="card-body">
          <p class="mb-0">
            Day {{ timeline.startDay }} Year {{ timeline.year }} - <span v-if="timeline.blockId!==null">#{{ timeline.blockId }}: </span><strong>{{ timeline.actionName }}</strong>
            <span class="day-counter text-muted">
              {{dayCounter(timeline.endDay)}}
            </span>
          </p>
          <p class="text-muted mb-0 small">
            {{ timeline.companyName }}: {{ timeline.fullName }}<br>{{ timeline.createDateTime }}
          </p>
          <div class="spinner-border spinner-border-sm text-warning" role="status" v-if="actualDay<timeline.endDay">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";

export default {
  name: "Timeline",
  props: ["initData", "contents", "simulationData"],
  data() {
    return {};
  },
  computed: {
    actualDay() {
      return this.simulationData.setup.actualDay;
    },
    histories() {
      return this.simulationData.histories;
    },
  },
  methods: {
    openOffset() {
      setTimeout(() => {
        let modalInstance = new Offcanvas(document.getElementById("timelineOffcanvas"));
        modalInstance.show();
      }, 0);
    },
    dayCounter(endDay){
      let daysLeft = endDay-this.actualDay
      if(daysLeft<=0){
        return 'finished'
      }
      else if(daysLeft===1){
        return '1 day left'
      }
      else{
        return daysLeft+' days left'
      }
    }
  },
};
</script>

<style scoped>
.offcanvas-body{
  background-color: var(--wrapper-bg);
}
.card-body{
  padding: 0.5rem 2rem 0.5rem 0.5rem;
}
.spinner-border{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.day-counter{
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  max-width: 2.5rem;
  text-align: right;
  font-size: 10px;
}
</style>
