<template>
  <div class="block"
       :style="{
      width: 100 / x + '%',
      height: 100 / y + '%',
      backgroundColor: getColor,
      }"
       @mouseover="handleHover"
       @click="handleClick"
  >
    {{blockData.id.slice(-4)}}
  </div>
</template>

<script>
import Simulation from "@/views/Simulation/Simulation.vue"

export default {
  name: "Block",
  props: ["initData", "simulationData", "contents", "blockData"],
  components: {Simulation},
  data() {
    let { setup } = this.simulationData;
    let { map } = setup;
    let { x, y } = map;

    return {
      x,
      y,
      Simulation,
      dispersionColors: setup.dispersionColors.colors
    }
  },
  computed:{
    lithology(){
      let tDD = this.blockData.tDD
      if(tDD){
        return tDD.li
      }
      return null
    },
    grainSize(){
      let tDD = this.blockData.tDD
      if(tDD){
        return tDD.gS
      }
      return null
    },
    hardness(){
      let tDD = this.blockData.tDD
      if(tDD){
        return tDD.hN
      }
      return null
    },
    status(){
      return this.blockData.s;
    },
    level(){
      return this.blockData.l;
    },
    actualLayer() {
      return this.simulationData.setup.actualLayer;
    },
    selectAnalyzeLayer() {
      return this.simulationData.setup.selectAnalyzeLayer;
    },
    selectTestLayer() {
      return this.simulationData.setup.selectTestLayer;
    },
    blocksForActions() {
      return this.simulationData.blocksForActions;
    },
    getColor() {
      if(this.blockData.hasOwnProperty('color')){
        return this.blockData.color
      }
      else if (this.blocksForActions.some((block) => block.id === this.blockData.id)) {
        return "red";
      } else if (this.actualLayer === 1) {
        return this.getLithologyColor(this.lithology);
      } else if (this.actualLayer === 2) {
        return this.getHardnessColor(this.hardness);
      } else if (this.actualLayer === 3) {
        return this.getStatusColor(this.status);
      } else if (this.actualLayer === 4) {
        return this.getGrainSizeColor(this.grainSize);
      }else if (this.actualLayer === 5) {
        if(this.blockData.hasOwnProperty('aD')){
          let analyzeData = this.blockData.aD
          if(analyzeData===null){
            return "#cecece";
          }
          let analyzeLayerArray = this.selectAnalyzeLayer.split('_')
          if(analyzeData.hasOwnProperty(analyzeLayerArray[0])){
            if(analyzeData[analyzeLayerArray[0]].hasOwnProperty(analyzeLayerArray[1])){
              let analyzeColorId = analyzeData[analyzeLayerArray[0]][analyzeLayerArray[1]].c
              let color = this.dispersionColors[analyzeColorId]
              return 'rgb('+color[0]+','+color[1]+','+color[2]+')'
            }
          }
        }
        return "#cecece";
      } else if (this.actualLayer >= 6 && this.actualLayer <= 13) {
        if (this.blockData.hasOwnProperty('tD')) {
          let testData = this.blockData.tD
          if (testData === null) {
            return "#cecece";
          }
          let testLayerArray = this.selectTestLayer.split('_')
          if(testData.hasOwnProperty(testLayerArray[0])){
            if(testData[testLayerArray[0]].hasOwnProperty(this.actualLayer - 5)){
              if(testData[testLayerArray[0]][this.actualLayer - 5].hasOwnProperty(testLayerArray[1])){
                let testColorId = testData[testLayerArray[0]][this.actualLayer - 5][testLayerArray[1]].c
                let color = this.dispersionColors[testColorId]
                return 'rgb('+color[0]+','+color[1]+','+color[2]+')'
              }
            }
          }

        }
      } else if (this.actualLayer === 15) {
        if (this.blockData.hasOwnProperty('tD')) {
          let testData = this.blockData.tD?.['4'].bondWorkIndex
          if (testData === undefined) {
            return "#cecece";
          }
          let color = this.dispersionColors[testData.c]
          console.log(this.blockData.tD)
          return 'rgb('+color[0]+','+color[1]+','+color[2]+')'
        }
      } else {
        return "#cecece";
      }
    },
    actualStage() {
      return this.simulationData.setup.actualStage;
    },
    actualLevel() {
      return this.simulationData.setup.actualLevel;
    },
    content() {
      return this.contents.map;
    },
    plantActivated() {
      return this.simulationData.constructions.plant.activated
    },
    plantBlocks() {
      return this.simulationData.constructions.plant.blocks
    },
    plantEntryActivated() {
      return this.simulationData.constructions.plantEntry.activated
    },
    plantEntryBlocks() {
      return this.simulationData.constructions.plantEntry.blocks
    },
    tailingEntryActivated() {
      return this.simulationData.constructions.tailingEntry.activated
    },
    tailingEntryBlocks() {
      return this.simulationData.constructions.tailingEntry.blocks
    },
    rawStockEntryActivated() {
      return this.simulationData.constructions.rawStockEntry.activated
    },
    rawStockEntryBlocks() {
      return this.simulationData.constructions.rawStockEntry.blocks
    },
  },
  methods: {
    handleHover() {
      this.simulationData.hoveredBlock = this.blockData;

      const { hoveredBlock, constructions, blocks, setup } = this.simulationData;
      const { map } = setup;
      const { actualLevel, actualStage } = this;

      const { x: hoveredX, y: hoveredY } = hoveredBlock
      const isHoveredBlockValid = hoveredBlock && (actualLevel === 1 && actualStage === 2);
      const isPlantActivated = constructions.plant.activated;
      const isPlantEntryEnabled = constructions.plantEntry.activated;
      const isTailingEntryEnabled = constructions.tailingEntry.activated;
      const isRawStockEntryEnabled = constructions.rawStockEntry.activated;

      const setBlockColor = (block, color) => {
        if (block) {
          block.color = color;
        }
      };

      const plantWidth = constructions.plant.rotated ? 10 : 5;
      const plantHeight = constructions.plant.rotated ? 5 : 10;

      if (isHoveredBlockValid && isPlantActivated) {
        const plantRightBottomX = hoveredX + plantWidth;
        const plantRightBottomY = hoveredY;
        const plantRightTopX = hoveredX + plantWidth;
        const plantRightTopY = hoveredY + plantHeight;
        const plantLeftTopX = hoveredX;
        const plantLeftTopY = hoveredY + plantHeight;
        const plantLeftBottomX = hoveredX;
        const plantLeftBottomY = hoveredY;

        const { x: mapWidth, y: mapHeight } = map;
        const isPlantOutsideMap =
            plantLeftBottomX < 0 ||
            plantLeftBottomY < 0 ||
            plantRightBottomX > mapWidth ||
            plantRightBottomY > mapHeight ||
            plantRightTopX > mapWidth ||
            plantRightTopY > mapHeight ||
            plantLeftTopX < 0 ||
            plantLeftTopY > mapHeight ||
            plantLeftBottomX < 0 ||
            plantLeftBottomY < 0 ||
            plantRightBottomX > mapWidth ||
            plantRightBottomY < 0 ||
            (
                plantLeftBottomX > 1 &&
                plantLeftTopY < mapHeight &&
                plantRightTopX < mapWidth &&
                plantRightBottomY > 1
            ) ||
          this.rawStockEntryBlocks.some((block) => {
            const { x, y } = block;
            return x >= plantLeftBottomX - 1 && x <= plantRightBottomX + 1 && y >= plantLeftBottomY - 1 && y <= plantLeftTopY + 1
          }) ||
          this.tailingEntryBlocks.some((block) => {
            const { x, y } = block;
            return x >= plantLeftBottomX - 1 && x <= plantRightBottomX + 1 && y >= plantLeftBottomY - 1 && y <= plantLeftTopY + 1
          })

        const plantAreaBlocks = blocks.filter((block) => {
          const { l, x, y } = block;
          return l === 1 && x >= plantLeftBottomX && x <= plantRightBottomX && y >= plantLeftBottomY && y <= plantLeftTopY
        });

        let blocksIds = []
        plantAreaBlocks.forEach((block) => {
          block.color = isPlantOutsideMap ? 'red' : 'green';
          blocksIds.push(block.id)
          if(!isPlantOutsideMap){
            this.simulationData.constructions.plant.blocks = plantAreaBlocks
            this.simulationData.constructions.plant.isValid = true
          }
        });
        if(isPlantOutsideMap){
          this.simulationData.constructions.plant.blocks = []
          this.simulationData.constructions.plant.isValid = false
        }

        this.simulationData.blocks.forEach((block) => {
          if(!blocksIds.includes(block.id)){
            delete block.color
          }
        });
      } else {
        this.simulationData.blocks.forEach((block) => {
          delete block.color
        });
      }

      const isEdgeOfMap = (x, y) => {
        return x === 1 || y === 1 || x === map.x || y === map.y;
      };

      const isPlantBlock = (x, y) => {
        return blocks.some((block) => block.x === x && block.y === y && block.l === 1 && block.s === 6);
      };

      const isAdjacentToPlant = (x, y) => {
        // Check if a block is directly adjacent to the plant (horizontally or vertically)
        return (
          isPlantBlock(x - 1, y) || // Left
          isPlantBlock(x + 1, y) || // Right
          isPlantBlock(x, y - 1) || // Above
          isPlantBlock(x, y + 1)    // Below
        );
      };

      const isEdgeOfPlant = (x, y) => {
        // Check if a block is on the edge of the plant (horizontally or vertically)
        return (
          isPlantBlock(x - 1, y) && // Left
          isPlantBlock(x + 1, y) && // Right
          isPlantBlock(x, y - 1) && // Above
          isPlantBlock(x, y + 1)    // Below
        );
      };

      const isTailingEntryBlock = (x, y) => {
        return blocks.some((block) => block.x === x && block.y === y && block.l === 1 && block.s === 8);
      };

      const isRawStockEntryBlock = (x, y) => {
        return blocks.some((block) => block.x === x && block.y === y && block.l === 1 && block.s === 9);
      };

      if (isHoveredBlockValid) {
        // Plant entrance - it can be placed only on the edge of the plant but not on the edge of the map
        if (isPlantEntryEnabled) {
          // if it is on the edge of the plant and not on the edge of the map
          this.simulationData.constructions.plantEntry.isValid = !isEdgeOfMap(hoveredX, hoveredY) && isPlantBlock(hoveredX, hoveredY) && !isEdgeOfPlant(hoveredX, hoveredY)
          this.simulationData.constructions.plantEntry.isValid ? setBlockColor(hoveredBlock, "lightblue") : setBlockColor(hoveredBlock, "red")
        }
        // Tailing entrance - it can be placed on the edge of the map, but can't be placed on the plant nor the next to the plant
        if (isTailingEntryEnabled) {
          this.simulationData.constructions.tailingEntry.isValid = isEdgeOfMap(hoveredX, hoveredY) && !isPlantBlock(hoveredX, hoveredY) && !isAdjacentToPlant(hoveredX, hoveredY) && !isRawStockEntryBlock(hoveredX, hoveredY)
          this.simulationData.constructions.tailingEntry.isValid ? setBlockColor(hoveredBlock, "cyan") : setBlockColor(hoveredBlock, "red")
        // Raw stock entrance - it can be placed on the edge of the map, but can't be placed on the plant nor the next to the plant
        } else if (isRawStockEntryEnabled) {
          this.simulationData.constructions.rawStockEntry.isValid = isEdgeOfMap(hoveredX, hoveredY) && !isPlantBlock(hoveredX, hoveredY) && !isAdjacentToPlant(hoveredX, hoveredY) && !isTailingEntryBlock(hoveredX, hoveredY)
          this.simulationData.constructions.rawStockEntry.isValid ? setBlockColor(hoveredBlock, "yellow") : setBlockColor(hoveredBlock, "red")
        }
      }
    },
    handleClick() {
      console.log(this.blockData.id)
      this.Simulation.methods.blockForAction(this, this.blockData.id)
    },
    getHardnessColor(hardnessId) {
      const hardness = this.simulationData.setup.hardness.find((s) => s.id === hardnessId);
      return hardness ? hardness.color : "#cecece";
    },
    getLithologyColor(lithologyId) {
      const lithology = this.simulationData.setup.lithologies.find((s) => s.id === lithologyId);
      return lithology ? lithology.color : "#cecece";
    },
    getGrainSizeColor(grainSizeId) {
      const grainSize = this.simulationData.setup.grainSizes.find((s) => s.id === grainSizeId);
      return grainSize ? grainSize.color : "#cecece";
    },
    getStatusColor(statusId) {
      const status = this.simulationData.setup.blockStatuses.find((s) => s.id === statusId);
      return status ? status.color : "Unknown";
    },
  },
};
</script>

<style scoped>
.block {
  float: left;
  color: white;
  font-size: 60%;
  text-align: center;
  line-height: 2rem;
  border: .5px solid black;
  cursor: pointer;
}
</style>
