<template v-if="simulationData.setup !== null">
  <div class="content-page">
    <div class="container-fluid">

      <div class="row">
        <div class="col-12">
          <div class="title-box">
            <div class="float-start">
              <h1><i class="fa-duotone fa-layer-group"></i> Map</h1>
              <router-link :to="{ name: 'simulationOverview' }">
                <i class="fa-duotone fa-arrow-left mb-3"></i> Overview
              </router-link>
            </div>

            <SubHeader :initData="initData" :simulationData="simulationData" :contents="contents"></SubHeader>

            <div class="clearfix"></div>

          </div>
        </div>
        <div class="col-12 col-md-8">
          <transition>
            <div class="map-container mb-3" v-show="mapTransitionShow" :class="{'animation-reverse':mapAnimationReverse,'animation':!mapAnimationReverse}">
              <Block
                v-for="(block, index) in mapSelectedLevelBlocks"
                :blockData="block"
                :simulationData="simulationData"
                :initData="initData"
                :contents="contents"
                :key="index"
              ></Block>
            </div>
          </transition>
          <div class="caption">
            <div v-if="this.actualLayer===1" class="label" v-for="(label,index) in lithologies" :key="index" :style="'width:'+100/lithologies.length+'%;border-color:'+label.color+';'">
              {{label.name}}
            </div>
            <div v-if="this.actualLayer===2" class="label" v-for="(label,index) in hardness" :key="index" :style="'width:'+100/hardness.length+'%;border-color:'+label.color+';'">
              {{label.name}}
            </div>
            <div v-if="this.actualLayer===3" class="label-left" v-for="(label,index) in blockStatuses" :key="index" :style="'width:'+100/(blockStatuses.length/2)+'%;border-color:'+label.color+';'">
              {{label.name}}
            </div>
            <div v-if="this.actualLayer===4" class="label" v-for="(label,index) in grainSizes" :key="index" :style="'width:'+100/grainSizes.length+'%;border-color:'+label.color+';'">
              {{label.name}}
            </div>
            <div v-if="this.actualLayer>=5 && this.actualLayer<=13" class="color" v-for="(color,index) in dispersionColors" :key="index" :style="'width:'+100/dispersionColors.length+'%;background-color:rgb('+color[0]+','+color[1]+','+color[2]+');'">
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="sticky-md-top">
          <div v-if="hoveredBlock">
            <h3 class="mb-1">#{{ hoveredBlock.id }}</h3>
            <p class="small">
              Level: {{ hoveredBlock.l }} X: {{ hoveredBlock.x }} Y: {{ hoveredBlock.y }}<br>
              Status: {{ this.getStatusName(hoveredBlock.s) }}<br>
              <span v-if="hoveredBlock.hasOwnProperty('pId')">Project <strong>#{{hoveredBlock.pId}}</strong></span><br>
              <span v-if="hoveredBlock.tDD!==null && hoveredBlock.s===3">Test drill finished</span><br>
              <template v-if="hoveredBlock.aD!==null">
                <span v-for="(value, analyzeId) in hoveredBlock.aD" :key="analyzeId">
                  <strong>{{this.getAnalyzeName(analyzeId)}}</strong> analyzed<br>
                </span>
                <span v-if="actualLayer==5">{{getAnalyzeLayerData(hoveredBlock.aD,selectAnalyzeLayer)}}</span><br>
              </template>
              <template v-if="hoveredBlock.tD!==null">
                <span v-for="(value, testId) in hoveredBlock.tD" :key="testId">
                  <strong>{{this.getTestName(testId)}}</strong> <span v-if="testId==3 || testId==4">tested</span><br>
                  <template v-if="testId==1 || testId==2" v-for="(flowSheet,flowSheetId) in value">
                    <small><strong>- {{this.getFlowSheetName(flowSheetId)}}</strong> tested<br></small>
                  </template>
                </span>
              </template>
              <template v-if="hoveredBlock.tD!==null">
                <span  v-if="actualLayer>5">{{getTestLayerData(hoveredBlock.tD,selectTestLayer)}}</span><br>
              </template>
            </p>
          </div>
          <div v-if="actualStage === 2 && realActualDay==actualDay">
            <h4 class="mb-3">Building operations</h4>
            <div class="mb-2">
              <button v-if="!this.plantPlaced" class="btn btn-sm btn-primary me-1" @click="togglePlantActive"
                      :disabled="this.plantEntryActivated || this.tailingEntryActivated || this.rawStockEntryActivated">
                <i class="fa-duotone fa-industry-windows"></i>
                {{ simulationData.constructions.plant.activated ? 'Deselect Plant' : 'Build Plant' }}
              </button>
              <button v-if="this.plantPlaced" class="btn btn-sm btn-danger me-1" @click="deletePlant">
                <i class="fa-duotone fa-trash"></i>
                Delete plant
              </button>
              <button v-if="this.plantActivated" class="btn btn-sm btn-primary me-1" @click="togglePlantRotate" :disabled="!this.plantActivated">
                <i class="fa-duotone fa-rotate-right"></i>
                Rotate plant
              </button>
            </div>
            <div class="mb-2">
              <button v-if="!this.plantEntryPlaced" class="btn btn-sm btn-primary" @click="togglePlantEntry" :disabled="this.tailingEntryActivated || this.rawStockEntryActivated || this.plantActivated || !this.plantPlaced">
                <i class="fa-duotone fa-person-to-door"></i>
                {{ simulationData.constructions.plantEntry.activated ? 'Deselect Plant Entrance' : 'Build Plant Entrance' }}
              </button>
              <button
                v-if="this.plantEntryPlaced"
                class="btn btn-sm btn-danger me-1"
                @click="deletePlantEntry"
              >
                <i class="fa-duotone fa-trash"></i>
                Delete plant entrance
              </button>
            </div>
            <div class="mb-2">
              <button
                v-if="!this.tailingEntryPlaced"
                class="btn btn-sm btn-primary"
                @click="toggleTailingEntry"
                :disabled="this.plantEntryActivated || this.rawStockEntryActivated || this.plantActivated"
              >
                <i class="fa-duotone fa-cubes"></i>
                {{ simulationData.constructions.tailingEntry.activated ? 'Deselect TSF Entry' : 'Build TSF entry' }}
              </button>
              <button
                v-if="this.tailingEntryPlaced"
                class="btn btn-sm btn-danger me-1"
                @click="deleteTailingEntry"
              >
                <i class="fa-duotone fa-trash"></i>
                Delete TSF entry
              </button>
            </div>
            <div class="mb-5">
              <button
                v-if="!this.rawStockEntryPlaced"
                class="btn btn-sm btn-primary"
                @click="toggleRawStockEntry"
                :disabled="this.plantEntryActivated || this.tailingEntryActivated || this.plantActivated"
              >
                <i class="fa-duotone fa-layer-group"></i>
                {{ simulationData.constructions.rawStockEntry.activated ? 'Deselect ROM stockpile Entry' : 'Build ROM stockpile Entry' }}
              </button>
              <button
                v-if="this.rawStockEntryPlaced"
                class="btn btn-sm btn-danger me-1"
                @click="deleteRawStockEntry"
              >
                <i class="fa-duotone fa-trash"></i>
                Delete ROM stockpile entry
              </button>
            </div>
            <div class="mb-3">
              <h4 class="mb-3">Flowsheet to processing</h4>
              <p>Instruction to flowsheet select...</p>
              <select class="form-control mb-2" v-model="flowSheet" @change="teamFlowSheet">
                <option value="">Select flowsheet</option>
                <option :value="flowSheet.id" v-for="flowSheet in blockFlowSheets">{{flowSheet.name}}</option>
              </select>
              <p class="small text-muted" v-if="flowSheetPrice!==null">Flowsheet price: {{nF.formatNumber(flowSheetPrice)}}<br>
                Flowsheet installation cost: {{nF.formatNumber(flowSheetCost)}}</p>
            </div>
            <div class="text-success" v-if="goalSuccessNumber>=goalNumber">
              <p>You finished planning and selected flowsheet, you can save and finished this phase with End phase button.<br>
                <small>It will be a longer process, please wait the end.</small>
              </p>
            </div>
          </div>
          <div
            v-if="isShaftRequired && realActualDay==actualDay"
            class="card mb-2 border-primary"
          >
            <div class="card-body bg-danger">
              <p class="mb-0 text-white">
                In the case of an underground project, the construction of a shaft is mandatory. Select a block where you would like to build it.
              </p>
            </div>
          </div>
            <div v-if="blocksForActions.length > 0 && realActualDay==actualDay">
              <div>
                <button v-if="!isShaftRequired"
                        class="btn btn-sm btn-primary me-1 mb-2"
                        :class="{'disabled':actualStage===1 && blocksForActions.filter((block) => block.s === 1).length===0 || shouldDisableTestDrill}"
                        @click="TestDrill.methods.openModal(this,blocksForActions)">
                  <i class="fa-duotone fa-bore-hole"></i> Drill hole
                </button>
                <button v-if="!isShaftRequired"
                        class="btn btn-sm btn-primary me-1 mb-2"
                        :class="{'disabled':actualStage===1 && blocksForActions.filter((block) => block.s === 3 && (block.aD===null || Object.keys(block.aD).length<2)).length===0 || shouldDisableAnalyze}"
                        @click="Analyze.methods.openModal(this,blocksForActions)">
                  <i class="fa-duotone fa-vial-virus"></i> Analyze
                </button>
                <button v-if="!isShaftRequired"
                        class="btn btn-sm btn-primary me-1 mb-2"
                        :class="{'disabled':actualStage===1 && blocksForActions.filter((block) => block.s === 3 && block.aD!==null).length===0 || shouldDisableTest}"
                        @click="Test.methods.openModal(this,blocksForActions)">
                  <i class="fa-duotone fa-flask-vial"></i> Test
                </button>
                <button v-if="actualStage===3 && !isShaftRequired"
                        class="btn btn-sm btn-primary me-1 mb-2"
                        :class="{'disabled':actualStage!==3 || this.disableBuildRoad()}"
                        @click="Road.methods.openModal(this,blocksForActions)">
                  <i class="fa-duotone fa-road"></i> Build road
                </button>
                <button
                    v-if="blocksForActions.every((block) => block.s === 10) && !isShaftRequired"
                    class="btn btn-sm btn-danger me-1 mb-2"
                    @click="deleteRoad"
                >
                  <i class="fa-duotone fa-trash"></i>
                  Delete road
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="disableStartProjectButton || this.disableBuildRoad()"
                    @click="NewProject.methods.openModal(this, blocksForActions, mapSelectedLevelBlocks, false)"
                >
                  <i class="fa-duotone fa-dungeon"></i> Start project
                </button>
                <button
                    v-if="isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.distanceFromProject()"
                    @click="OpenShaft.methods.openModal(this, blocksForActions)"
                >
                  <i class="fa-duotone fa-fan"></i> Build project ventilation shaft
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableMineButton && actualStage===3"
                    @click="Mine.methods.openModal(this, blocksForActions, simulationData.blocks)"
                >
                  <i class="fa-duotone fa-pickaxe"></i> Mine
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableBackfillingButton && actualStage===3"
                    @click="Backfilling.methods.openModal(this, blocksForActions, simulationData.blocks)"
                >
                  <i class="fa-duotone fa-fill-drip"></i> Backfilling
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableProcessingButton && actualStage===3"
                    @click="Processing.methods.openModal(this, blocksForActions, simulationData.blocks)"
                >
                  <i class="fa-duotone fa-microchip"></i> Processing
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableSellButton && actualStage===3"
                    @click="Sell.methods.openModal(this, blocksForActions, simulationData.blocks)"
                >
                  <i class="fa-duotone fa-money-bills"></i> Sell
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableTunnelButton && actualStage===3"
                    @click="Tunnel.methods.openModal(this, blocksForActions, simulationData.blocks)"
                >
                  <i class="fa-duotone fa-car-tunnel"></i> Tunnel
                </button>
                <button
                    v-if="actualStage === 3 && !isShaftRequired"
                    class="btn btn-sm btn-primary me-1 mb-2"
                    :disabled="this.disableSwitchButton && actualStage===3"
                    @click="NewProject.methods.openModal(this, blocksForActions, mapSelectedLevelBlocks, true)"
                >
                  <i class="fa-regular fa-space-station-moon-construction"></i> Switch to underground project
                </button>
                <p class="small text-muted mb-1">
                  Choose action for selected blocks
                </p>
              </div>
              <hr class="mt-0 mb-4">
              <div v-if="blocksForActions.length==2 && blocksForActions.filter(block => block.l == blocksForActions[0]['l']).length==2" class="btn btn-sm btn-primary float-end" title="Fill out between two blocks" @click="fillOut"><i class="fa-duotone fa-fill"></i></div>
              <h3 class="mb-3">Selected block(s) to action</h3>
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                  <tr class="small">
                    <th><button class="btn btn-sm btn-danger" @click="removeAllFromBlocksForAction()" title="Unselect all"><i class="fa-duotone fa-trash-can-list"></i></button></th>
                    <th>Id</th>
                    <th>Level</th>
                    <th>X</th>
                    <th>Y</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(block, index) in blocksForActions" :key="index">
                    <td>
                      <button class="btn btn-sm btn-danger" @click="removeFromActions(block.id)" title="Delete block from action list">
                        <i class="fa-duotone fa-delete-right"></i>
                      </button>
                    </td>
                    <td>
                      #{{ block.id }}
                    </td>
                    <td>
                      {{ block.l }}
                    </td>
                    <td>
                      {{ block.x }}
                    </td>
                    <td>
                      {{ block.y }}
                    </td>
                    <td>
                      {{ getStatusName(block.s) }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Block from "@/views/Simulation/Map/Block.vue";
import SubHeader from "@/views/Simulation/components/SubHeader.vue";
import TestDrill from "@/views/Simulation/Modal/TestDrill";
import Road from "@/views/Simulation/Modal/Road"
import Analyze from "@/views/Simulation/Modal/Analyze.vue";
import Simulation from "@/views/Simulation/Simulation.vue";
import Test from "@/views/Simulation/Modal/Test.vue";
import NewProject from "@/views/Simulation/Modal/NewProject"
import OpenShaft from "@/views/Simulation/Modal/OpenShaft.vue";
import Mine from "@/views/Simulation/Modal/Mine.vue"
import {Response} from "@/classes/response";
import { NumberFunctions} from "@/classes/number";
import Sell from "@/views/Simulation/Modal/Sell.vue";
import Tunnel from "@/views/Simulation/Modal/Tunnel.vue";
import Processing from "@/views/Simulation/Modal/Processing.vue";
import Backfilling from "@/views/Simulation/Modal/Backfilling.vue";

export default {
  name: "Map",
  components: { SubHeader, Block, TestDrill, Analyze, Simulation, Road, NewProject, OpenShaft, Mine },
  props: ["initData", "contents", "simulationData"],
  data() {
    const { blocks } = this.simulationData;
    const { setup } = this.simulationData;
    const { map } = setup;
    const { x } = map;
    const { y } = map;
    const permissionLevel = this.initData.user.permissionLevel
    const myCompany = this.initData.user.myCompany

    let actualLayer = localStorage.getItem('vMine_'+this.initData.user.selectedCourseId+'_actualLayer')
    if(actualLayer===null){
      actualLayer = setup.actualLayer
    }else{
      setup.actualLayer = parseInt(actualLayer)
    }

    let nF = new NumberFunctions()

    return {
      nF,
      x,
      y,
      blockId: null,
      blockLevel: null,
      blockX: null,
      blockY: null,
      blockStatus: null,
      blockStatuses: this.simulationData.setup.blockStatuses,
      hardness: this.simulationData.setup.hardness,
      grainSizes: this.simulationData.setup.grainSizes,
      lithologies: this.simulationData.setup.lithologies,
      blockAnalysis: this.simulationData.setup.blockAnalysis,
      blockTests: this.simulationData.setup.blockTests,
      blockFlowSheets: this.simulationData.setup.flowSheets,
      dispersionColors: this.simulationData.setup.dispersionColors.colors,
      flowSheet : '',
      TestDrill,
      Analyze,
      Test,
      Simulation,
      Road,
      NewProject,
      OpenShaft,
      Mine,
      Backfilling,
      Processing,
      Tunnel,
      Sell,
      permissionLevel,
      myCompany
    };
  },
  mounted() {
    // CONTAINER
    const mapContainer = document.querySelector(".map-container");
    // SET HEIGHT AND WIDTH
    const mapContainerWidth = mapContainer.offsetWidth;
    // SET HEIGHT
    mapContainer.style.height = mapContainerWidth + "px";
  },
  computed: {
    blocks() {
      return this.simulationData.blocks;
    },
    actualLayer() {
      return this.simulationData.setup.actualLayer
    },
    selectAnalyzeLayer(){
      return this.simulationData.setup.selectAnalyzeLayer
    },
    selectTestLayer() {
      return this.simulationData.setup.selectTestLayer
    },
    isTransitionInProgress(){
      return this.simulationData.setup.map.isTransitionInProgress;
    },
    mapAnimationReverse(){
      return this.simulationData.setup.map.mapAnimationReverse;
    },
    mapTransitionShow(){
      return this.simulationData.setup.map.mapTransitionShow;
    },
    shouldDisableTestDrill() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1);

      return blockStatuses.length !== blocks.length
    },
    shouldDisableAnalyze() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1 || block.s === 3 || block.s === 21);

      return blockStatuses.length !== blocks.length
    },
    shouldDisableTest() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1 || block.s === 3 || block.s === 21);

      return blockStatuses.length !== blocks.length
    },
    disableStartProjectButton() {
      const blocksForActions = this.simulationData.blocksForActions;

      // Check if there are any blocks in blocksForActions
      if (blocksForActions.length !== 1) {
        return true;
      }

      // Check if any of the blocks have neighbors with block.s === 10
      const hasNeighborWithS10 = blocksForActions.some((block) => {
        // Implement logic to check if the block has a neighbor with block.s === 10
        // You may need to adjust the logic based on your specific block structure
        const neighbor = this.getNeighborWithS10(block);
        return neighbor !== null;
      });

      return !hasNeighborWithS10;
    },
    disableMineButton() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1 ||
        block.s === 3 || block.s === 14
      );

      return blockStatuses.length !== blocks.length
    },
    disableBackfillingButton() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 9 ||block.s === 14 ||
        block.s === 26 ||
        block.s === 24 ||
        block.s === 21
      );

      return blockStatuses.length !== blocks.length
    },
    disableTunnelButton() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1 ||
        block.s === 3 ||
        block.s === 16 ||
        block.s === 27
      );

      return blockStatuses.length !== blocks.length
    },
    disableProcessingButton() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 21);

      return blockStatuses.length !== blocks.length
    },
    disableSellButton() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 24);

      return blockStatuses.length !== blocks.length
    },
    disableSwitchButton() {
      let blocks = this.blocksForActions;
      if (blocks.length !== 1) {
        return true;
      }
      let block = blocks[0]

      // check if the block underneath has a status of 9, 21, 26 or 24
      let blockUnderneath = this.getBlockAt(block.x, block.y, block.l + 1);

      const validStatuses = [9, 21, 26, 24];

      // Check if both the selected block and the block underneath have a block.s of 9, 21, 26 or 24
      if (validStatuses.includes(block.s) && !validStatuses.includes(blockUnderneath.s)) {
        // Check if the block.pId belongs to an open-pit project
        let project = this.simulationData.projects.find(project => project.id === block.pId);
        if (project.type === 1) {
          return false;
        }
      }

      return true;
    },
    plantActivated() {
      return this.simulationData.constructions.plant.activated
    },
    plantPlaced() {
      return this.simulationData.constructions.plant.placed
    },
    plantEntryActivated() {
      return this.simulationData.constructions.plantEntry.activated
    },
    plantEntryPlaced() {
      return this.simulationData.constructions.plantEntry.placed
    },
    tailingEntryActivated() {
      return this.simulationData.constructions.tailingEntry.activated
    },
    tailingEntryPlaced() {
      return this.simulationData.constructions.tailingEntry.placed
    },
    rawStockEntryActivated() {
      return this.simulationData.constructions.rawStockEntry.activated
    },
    rawStockEntryPlaced() {
      return this.simulationData.constructions.rawStockEntry.placed
    },
    hoveredBlock() {
      return this.simulationData.hoveredBlock;
    },
    blocksForActions(){
      return this.simulationData.blocksForActions
    },
    actualLevel() {
      return this.simulationData.setup.actualLevel;
    },
    actualDay() {
      return this.simulationData.setup.actualDay;
    },
    realActualDay() {
      return this.simulationData.setup.realActualDay;
    },
    actualYear() {
      return this.simulationData.setup.actualYear;
    },
    actualStage() {
      return this.simulationData.setup.actualStage;
    },
    flowSheetPrice(){
      let flowSheet = this.blockFlowSheets.find((flowSheet) => flowSheet.id === parseInt(this.flowSheet));
      if(!flowSheet){
        return null
      }
      return flowSheet.ballMill + flowSheet.sagMill + flowSheet.gravitySpirals + flowSheet.flotation + flowSheet.magneticSeparation
    },
    flowSheetCost(){
      let flowSheet = this.blockFlowSheets.find((flowSheet) => flowSheet.id === parseInt(this.flowSheet));
      if(!flowSheet){
        return null
      }
      return flowSheet.cost
    },
    mapSelectedLevelBlocks() {
      const selectedLayer = [];
      const x = this.x;
      const y = this.y;
      const blocks = this.blocks;
      const actualLevel = this.actualLevel;
      // Get layer
      const actualBlocks = blocks.slice(actualLevel * x * y - x * y, actualLevel * x * y);
      // Calculate the selectedLayer array with reordered blocks
      for (let i = y - 1; i >= 0; i--) {
        for (let j = 0; j < x; j++) {
          const index = j * y + i;
          selectedLayer.push(actualBlocks[index]);
        }
      }
      return selectedLayer;
    },
    stage() {
      return this.simulationData.setup.actualStage
    },
    goalNumber(){
      return Object.keys(this.simulationData.goals[this.stage]).length
    },
    goalSuccessNumber(){
      let goals = this.simulationData.goals[this.stage]
      //GET SUCCESS GOALS
      let successGoals = Object.keys(goals).filter((goal) => goals[goal].status === true)
      return successGoals.length
    },
    isShaftRequired(){
      return this.simulationData.isShaftRequired
    }
  },
  methods: {
    fillOut(){
      let blocks = this.blocksForActions;
      if(blocks.length!==2){
        return false
      }
      //SORT BLOCKS BY X,Y
      blocks.sort((a, b) => a.x - b.x || a.y - b.y);
      //START BLOCK
      let startBlock = blocks[0]
      //END BLOCK
      let endBlock = blocks[1]
      //FILL BETWEEN TWO COORDS
      let fillBlocks = new Set();

      let startBlockY = startBlock.y
      let endBlockY = endBlock.y
      if(startBlockY>endBlockY){
        startBlockY = endBlockY
        endBlockY = startBlock.y
      }
      //FILL X
      for(let i=startBlock.x;i<=endBlock.x;i++){
        fillBlocks.add(this.getBlockAt(i,startBlock.y,startBlock.l))
        //FILL Y
        for(let j=startBlockY;j<=endBlockY;j++){
          fillBlocks.add(this.getBlockAt(i,j,startBlock.l))
        }
      }
      this.simulationData.blocksForActions = Array.from(fillBlocks);
    },
    deleteRoad() {
      const blocksToDelete = this.blocksForActions;

      blocksToDelete.forEach((block, index) => {
        block.s = block.oS
      });

      let roadConnectionCheck = Simulation.methods.roadConnectionCheck(this, this.mapSelectedLevelBlocks)

      blocksToDelete.forEach((block, index) => {
        block.oS = block.s
        block.s = 10
      });

      if (!roadConnectionCheck.globalStatus) {
        this.initData.alerts.push({msg: 'Road deletion failed. Please check road connections.', type: 'error'});
        return false
      }

      this.Simulation.methods.action(this, 18, this.blocksForActions, roadConnectionCheck)
    },

    getNeighbors(block) {
      const neighbors = [];
      const { x, y, l } = block;

      // Check for neighbors to the left, right, top, and bottom
      if (x > 0) neighbors.push(this.getBlockAt(x - 1, y, l));
      if (x < this.y) neighbors.push(this.getBlockAt(x + 1, y, l));
      if (y > 0) neighbors.push(this.getBlockAt(x, y - 1, l));
      if (y < this.x) neighbors.push(this.getBlockAt(x, y + 1, l));

      return neighbors.filter((neighbor) => neighbor !== undefined);
    },

    getBlockAt(x, y, l) {
      // Assuming you have access to the blocks array
      return this.blocks.find((block) => block.x === x && block.y === y && block.l === l);
    },

    getNeighborWithS10(block) {
      const neighbors = this.getNeighbors(block);

      // Check if any neighbor has block.s === 10
      for (const neighbor of neighbors) {
        if (neighbor.s === 10) {
          return neighbor; // Found a neighbor with block.s === 10
        }
      }

      // No neighbor with block.s === 10 found
      return null;
    },

    isAbleToMine() {
      const neighbors = [];

      this.blocksForActions.map((b) => {
        neighbors.push(this.getNeighbors(b));
      });

      for (const neighbor of neighbors) {
        // filter out the neighbors that have block.s === 13
        const filteredNeighbors = neighbor.filter((n) => n.s === 13 || n.s === 17 || n.s === 18 || n.s === 19 || n.s === 20);
        // if the filteredNeighbors is empty, return true
        if (filteredNeighbors.length === 0) {
          return true;
        }
      }
    },

    disableBuildProject(e, blocks, map) {
      e.simulationData.modals.newProject.blocks = blocks

      const entrances = {
        plant: 7,
        tailings: 8,
        rawStock: 22,
      };
      const projectEntrance = blocks[0]

      const plantEntrance = map.find((block) => block.s === entrances.plant);
      const tailingEntrance = map.find((block) => block.s === entrances.tailings);
      const rawStockEntrance = map.find((block) => block.s === entrances.rawStock);

      const plantPathLength = Simulation.methods.findPath(e, projectEntrance, plantEntrance, map, 10);
      const tailingPathLength = Simulation.methods.findPath(e, projectEntrance, tailingEntrance, map, 10);
      const rawStockPathLength = Simulation.methods.findPath(e, projectEntrance, rawStockEntrance, map, 10);

      // if any of the paths is null, return true
      return !(plantPathLength !== false && tailingPathLength !== false && rawStockPathLength !== false);
    },
    distanceFromProject() {
      const projects = this.simulationData.projects;
      // get specific project by projectID
      const lastMinedProject = projects.find(project => project.id === this.simulationData.shaftRequiredProjectId)

      // Use switchEntrance coordinates if switchedToUnderGround is true
      const entrance = lastMinedProject.switchedToUnderground ? lastMinedProject.switchEntrance : lastMinedProject.entrance;

      if (this.blocksForActions.length === 1) {
        // selected block
        const blockData = this.blocksForActions[0];
        // get the block underneath
        const blockUnderneath = this.getBlockAt(blockData.x, blockData.y, blockData.l + 1) ?? 0;
        // get the block above
        const blockAbove = this.getBlockAt(blockData.x, blockData.y, blockData.l - 1) ?? 0;

        // distance from the entrance of the last project
        const distance = Math.sqrt(Math.pow(blockData.x - entrance.x, 2) + Math.pow(blockData.y - entrance.y, 2));

        // valid statuses for the block above
        const validStatusesAbove = [undefined, 9, 21, 26, 24];
        // invalid statuses for the block
        const invalidStatusesBlock = [6, 7, 10, 11, 12, 13, 15, 22, 27];
        // valid statuses for the block underneath
        const validStatusesUnderneath = [1, 28];

        // if the block above has a valid status, the selected block has not an invalid status and the block underneath has a valid status and the distance isnt above 7, return false
        return !(validStatusesAbove.includes(blockAbove.s) && !invalidStatusesBlock.includes(blockData.s) && validStatusesUnderneath.includes(blockUnderneath.s) && distance <= 7);
      } else {
        return true;
      }
    },
    disableBuildRoad() {
      let blocks = this.blocksForActions;
      let blockStatuses = blocks.filter((block) => block.s === 1 ||
        block.s === 2 ||
        block.s === 3 ||
        block.s === 4 ||
        block.s === 5
      );

      const isAllGroundLevel = blocks.every((block) => block.l === 1);

      return blockStatuses.length !== blocks.length || !isAllGroundLevel;
    },
    teamFlowSheet(){
      this.simulationData.setup.teamFlowSheet = this.flowSheet
    },
    deletePlant() {
      // Get the old block statuses
      this.simulationData.constructions.plant.blocks.forEach((plantBlock) => {
        const blockId = plantBlock.id
        const block = this.simulationData.blocks.find((block) => block.id === blockId);
        block.s = block.oldS;
      });

      // Update the plant activation status
      this.simulationData.constructions.plant.placed = false;
      this.simulationData.constructions.plant.activated = false;
      this.simulationData.constructions.plant.isValid = false;
      this.deletePlantEntry()
    },
    togglePlantActive() {
      this.simulationData.setup.actualLevel = 1
      this.simulationData.constructions.plant.activated = !this.simulationData.constructions.plant.activated;
      if (!this.simulationData.constructions.plant.activated) {
        this.simulationData.blocks.forEach((block) => {
          delete block.color
        });
      }
    },
    togglePlantRotate() {
      this.simulationData.constructions.plant.rotated = !this.simulationData.constructions.plant.rotated;
    },
    togglePlantEntry() {
      this.simulationData.setup.actualLevel = 1
      this.simulationData.constructions.plantEntry.activated = !this.simulationData.constructions.plantEntry.activated;
    },
    deletePlantEntry() {
      // search all the blocks with s 7 and make it s 6
      this.simulationData.constructions.plantEntry.blocks.forEach((plantEntryBlock) => {
        this.simulationData.constructions.plantEntry.blocks = []
        const blockId = plantEntryBlock.id
        const block = this.simulationData.blocks.find((block) => block.id === blockId);
        if(block.s===7){
          block.s = 6;
        }
      });

      // Update the plant activation status
      this.simulationData.constructions.plantEntry.placed = false;
      this.simulationData.constructions.plantEntry.activated = false;
      this.simulationData.constructions.plantEntry.isValid = false;
    },
    toggleTailingEntry() {
      this.simulationData.setup.actualLevel = 1
      this.simulationData.constructions.tailingEntry.activated = !this.simulationData.constructions.tailingEntry.activated;
    },
    deleteTailingEntry() {
      // Get the old block statuses from this.simulationData.constructions.tailingEntry.oldBlocks
      this.simulationData.constructions.tailingEntry.blocks.forEach((tailingEntryBlock) => {
        this.simulationData.constructions.tailingEntry.blocks = []
        const blockId = tailingEntryBlock.id
        const block = this.simulationData.blocks.find((block) => block.id === blockId);
        block.s = block.oldS;
      });

      // Update the plant activation status
      this.simulationData.constructions.tailingEntry.placed = false;
      this.simulationData.constructions.tailingEntry.activated = false;
      this.simulationData.constructions.tailingEntry.activated = false;
    },
    toggleRawStockEntry() {
      this.simulationData.setup.actualLevel = 1
      this.simulationData.constructions.rawStockEntry.activated = !this.simulationData.constructions.rawStockEntry.activated;
    },
    deleteRawStockEntry() {
      // Get the old block statuses from this.simulationData.constructions.rawStockEntry.oldBlocks
      this.simulationData.constructions.rawStockEntry.blocks.forEach((rawStockEntryBlock) => {
        this.simulationData.constructions.rawStockEntry.blocks = []
        const block = this.simulationData.blocks.find((block) => block.id === rawStockEntryBlock.id);
        block.s = block.oldS;
      });

      // Update the plant activation status
      this.simulationData.constructions.rawStockEntry.placed = false;
      this.simulationData.constructions.rawStockEntry.activated = false;
      this.simulationData.constructions.rawStockEntry.isValid = false;
    },
    getStatusName(statusId) {
      const status = this.simulationData.setup.blockStatuses.find((s) => s.id === statusId);
      return status ? status.name : "Unknown";
    },
    getAnalyzeName(analyzeId) {
      const analyze = this.blockAnalysis.find((a) => a.id == analyzeId);
      return analyze ? analyze.name : "Unknown";
    },
    getAnalyzeLayerData(analyzeData,selectAnalyzeLayer){
      if(analyzeData===null){
        return '';
      }
      let analyzeLayerArray = selectAnalyzeLayer.split('_')
      if(analyzeData.hasOwnProperty(analyzeLayerArray[0])){
        if(analyzeData[analyzeLayerArray[0]].hasOwnProperty(analyzeLayerArray[1])){
          let analyzeName = analyzeLayerArray[1].charAt(0).toUpperCase() + analyzeLayerArray[1].slice(1)
          if(analyzeName==='GrainSizeMicrons' || analyzeName==='SpecificGravity'){
            return analyzeName + ': ' + analyzeData[analyzeLayerArray[0]][analyzeLayerArray[1]].v
          }else{
            return analyzeName + ': ' + analyzeData[analyzeLayerArray[0]][analyzeLayerArray[1]].v + '%'
          }
        }
        return ''
      }
    },
    getTestName(testId) {
      const test = this.blockTests.find((t) => t.id == testId);
      return test ? test.name : "Unknown";
    },
    getTestLayerData(testData,selectTestLayer){
      if(testData===null){
        return '';
      }
      let testLayerArray = selectTestLayer.split('_')
      if(testData.hasOwnProperty(testLayerArray[0])){
        if(testData[testLayerArray[0]] && testData[testLayerArray[0]][this.actualLayer - 5] && testData[testLayerArray[0]][this.actualLayer - 5].hasOwnProperty(testLayerArray[1])){
          let testName = testLayerArray[1].charAt(0).toUpperCase() + testLayerArray[1].slice(1)
          return testName + ': ' + testData[testLayerArray[0]][this.actualLayer - 5][testLayerArray[1]].v + '%'
        }
        return ''
      }
    },
    getFlowSheetName(flowSheetId) {
      const flowSheet = this.blockFlowSheets.find((f) => f.id == flowSheetId);
      return flowSheet ? flowSheet.name : "Unknown";
    },
    removeFromActions(blockId){
      //GET BLOCK DATA
      const blockData = this.simulationData.blocks.find((block) => block.id === blockId);
      if(this.actualStage===1){
        //FILTER BLOCKS WITHOUT TDD
        let blocksWithoutTdd = this.blocksForActions.filter((block) => block.tDD === null && block.id !== blockId)
        if(blocksWithoutTdd.length>0){
          //REMOVE ALL BLOCKS
          this.simulationData.blocksForActions = [];
        }
      }
      //REMOVE ONLY ONE BLOCK
      this.simulationData.blocksForActions = this.blocksForActions.filter((block) => block.id !== blockId)
      //ORDER BY
      this.simulationData.blocksForActions.sort((a, b) => (a.id > b.id) ? 1 : -1)
      //REFRESH LOCAL STORAGE
      localStorage.setItem('vMine_'+this.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(this.simulationData.blocksForActions));
    },
    removeAllFromBlocksForAction(){
      this.simulationData.blocksForActions = [];
      localStorage.setItem('vMine_'+this.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(this.simulationData.blocksForActions));
    },
  },
};
</script>

<style scoped>
/*VUE ANIMATION WITH SCALE*/
:deep(.map-container.animation.v-enter-active) {
  animation: bounce-in .4s;
}
:deep(.map-container.animation.v-leave-active) {
  animation: bounce-out .4s;
}
:deep(.map-container.animation-reverse.v-enter-active) {
  animation: bounce-in-reverse .4s;
}
:deep(.map-container.animation-reverse.v-leave-active) {
  animation: bounce-out-reverse .4s;
}



.reset-btn{
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 999;
}

.caption .label{
  float: left;
  border-top-width: 4px;
  border-top-style: solid;
  font-size: 0.8rem;
  text-align: center;
  padding: 0 3px;
  word-break: break-word;
}

.caption .label-left{
  float: left;
  border-top-width: 4px;
  border-top-style: solid;
  font-size: 0.8rem;
  line-height: 0.9rem;
  min-height: 3.2rem;
  text-align: center;
  padding: 2px 3px;
  word-break: break-word;
  margin-bottom: 1rem;
}
.caption .color{
  float: left;
  height: 0.5rem;
}

@keyframes bounce-out {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(20px, -20px);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translate(-20px, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes bounce-out-reverse {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-20px, 20px);
  }
}

@keyframes bounce-in-reverse {
  0% {
    opacity: 0;
    transform: translate(20px, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
</style>
