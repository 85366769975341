<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="pricesOffcanvas" aria-labelledby="pricesOffcanvasLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"><i class="fa-duotone fa-money-bill-wave"></i> Prices and news</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-md-12">
          <div v-if="simulationData.prices && simulationData.prices.histories">
            <div
              v-for="(price, index) in simulationData.prices.histories"
              :key="index"
              class="card mb-1"
              :class="{'border-secondary': actualDay >= price.dayEnd, 'border-success': actualDay < price.dayEnd}"
            >
              <div class="card-body">
                <p class="mb-0">
                  Day {{ price.dayStart }} - {{ price.dayEnd }} <strong class="float-end">{{ nF.formatNumber(price.price) }}</strong>
                </p>
                <p class="text-muted mb-0 small">
                  {{ price.news || 'No news available' }}<br>
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <p>No price data available.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { Line } from "vue-chartjs";
import {
  CategoryScale,
  Chart as ChartJS, Colors,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import {NumberFunctions} from "@/classes/number";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
)

export default {
  name: "Prices",
  props: ["initData", "contents", "simulationData"],
  components: {
    Line,
  },
  data() {

    let nF = new NumberFunctions()


    return {
      nF,
    };
  },
  computed: {
    actualDay() {
      return this.simulationData.setup.actualDay;
    },
  },
  methods: {
    openOffset() {
      setTimeout(() => {
        let modalInstance = new Offcanvas(document.getElementById("pricesOffcanvas"));
        modalInstance.show();
      }, 0);
    },
  },
};
</script>

<style scoped>
.offcanvas-body {
  background-color: var(--wrapper-bg);
}
.card-body {
  padding: 0.5rem;
}
.offcanvas {
  width: 70%;
}
</style>
